module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"강남 달토","short_name":"강남 달토","description":"강남 달토 가라오케의 공식 사이트입니다. 최고로 즐겁게 모시겠습니다. 방문 전 전화 꼭 부탁드립니다. 양주13만원, 티씨 12만원, 룸비 5만원 입니다.","start_url":"/","display":"standalone","theme_color":"#000000","background_color":"#000000","lang":"ko","orientation":"portrait-primary","icon":"./src/static/images/icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"289f7a2ffb17dfd5ff4a26985101d8aa"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
